import React from "react"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const pagoExitoF = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
        title="Pago Exitoso"
        titleTemplate="%s | Eduardo Ceballos"
        noindex={true}
        nofollow={true}
      />

      <h1>Pago Exitoso</h1>

      <p>
        Su pago se realizó con éxito, nos pondremos en contacto con usted lo
        antes posible para arreglar el envió del producto.
      </p>
    </Layout>
  )
}

export default pagoExitoF
